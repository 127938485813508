.carousel-item {
  /* height: 80vh !important; */
  max-height: 30rem !important;
  width: 100% !important;
  /* background-size: cover !important; */
  object-fit: cover !important;
}

.carousel-item img {
  object-fit: cover !important;
  max-height: 31rem !important;
}

.carousel-caption {
  max-height: 22rem;
  background-color: rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 2rem;
}
