/* map responsive */
@media screen and (max-width:600px) {
    .contact_container>div.row {
        flex-direction: column;
        padding: 2rem;
        max-width: 100% !important;
    }
}

.contact_container>div.row {
    max-width: 100% !important;
}

.map-responsive {
    overflow: hidden;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 400px;
    width: 100%;
}