.main {
    background-color: rgb(28, 64, 105);
    margin-top: 2rem;
    color: white;
}

/* 
.overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
} */